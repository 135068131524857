var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "cart-header" },
                [
                  _c("b-col", { attrs: { cols: "5" } }, [
                    _c("div", { staticClass: "delivery-detail" }, [
                      _c("h2", [_vm._v("Luganze")]),
                      _c("h4", [_vm._v("Fast Food, Continental")]),
                      _c("h5"),
                      _c("h3", [
                        _vm._v("45 Mins "),
                        _c("span", [_vm._v("Delivery ")])
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-2 border-top" },
                [
                  _vm.categories.length
                    ? _c(
                        "VueSlickCarousel",
                        _vm._b(
                          {},
                          "VueSlickCarousel",
                          _vm.sliderSettings,
                          false
                        ),
                        _vm._l(_vm.categories, function(category) {
                          return _c(
                            "div",
                            {
                              key: category.id,
                              staticClass: "m-2 item-categories",
                              class: [
                                category.id == _vm.activeCategory
                                  ? "active"
                                  : ""
                              ],
                              on: {
                                click: function($event) {
                                  _vm.activeCategory = category.id
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(category.name) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-row",
                _vm._l(_vm.grouped, function(item) {
                  return _c(
                    "b-col",
                    { key: item.id, attrs: { sm: "12", md: "6" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-card-body",
                                    {
                                      staticClass: "item-card",
                                      attrs: { title: item.name }
                                    },
                                    [
                                      _c("b-card-text", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "align-items-left item-list"
                                          },
                                          [
                                            _c("h4", [
                                              _vm._v("$" + _vm._s(item.price))
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                " 6pcs fired/grilled, someting, there it it, fired and fired stuffs all over! "
                                              )
                                            ])
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { md: "6" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "w-100" },
                                    [
                                      _c("b-card-img", {
                                        staticClass: "rounded-0 item-img",
                                        attrs: {
                                          src:
                                            "https://picsum.photos/400/400/?image=20",
                                          alt: "Image"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-100" },
                                    [
                                      _c("b-form-spinbutton", {
                                        attrs: {
                                          id: "demo-sb",
                                          max: "100",
                                          min: "0",
                                          inline: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.spin(item, $event)
                                          }
                                        },
                                        model: {
                                          value: item.qty,
                                          callback: function($$v) {
                                            _vm.$set(item, "qty", $$v)
                                          },
                                          expression: "item.qty"
                                        }
                                      }),
                                      item.qty == 0
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "add-to-cart-btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.addToCart(item)
                                                }
                                              }
                                            },
                                            [_vm._v("Add")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  item.menu_modifiers
                                    ? _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.customize",
                                              modifiers: { customize: true }
                                            }
                                          ],
                                          attrs: { variant: "link" },
                                          on: {
                                            click: function($event) {
                                              return _vm.customizeOrder(item)
                                            }
                                          }
                                        },
                                        [_vm._v(" Customize ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _vm.$store.state["cart"].cartItems.length > 0
                ? _c(
                    "div",
                    { staticClass: "bg-gradient-secondary footer" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-1",
                                      modifiers: { "modal-1": true }
                                    }
                                  ]
                                },
                                [_vm._v(" Cart ")]
                              ),
                              _vm._v(
                                " Your Order (" + _vm._s(_vm.totalQty) + ") "
                              )
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { cols: "6" } }, [
                            _c("p", [
                              _vm._v(
                                " Total " + _vm._s(_vm.totalAmount.toFixed(2))
                              )
                            ])
                          ]),
                          _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "cart" } },
                                [_c("b-button", [_vm._v("PROCEED")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-1",
            title: "Your Orders",
            "modal-class": "cart-details-modal",
            size: "xl",
            "hide-footer": true
          }
        },
        [
          _c("b-container", [
            _c(
              "div",
              { staticClass: "header" },
              _vm._l(_vm.$store.state["cart"].cartItems, function(item, index) {
                return _c(
                  "b-row",
                  { key: "cart-" + item.id },
                  [
                    _c(
                      "b-card",
                      { staticClass: "w-100 mb-1" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "8" }
                              },
                              [
                                _vm._v(" " + _vm._s(item.name) + " "),
                                item.selected_groups.length > 0
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "p",
                                          _vm._l(item.selected_groups, function(
                                            modifier
                                          ) {
                                            return _c(
                                              "span",
                                              { key: modifier.id },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(modifier.name) +
                                                      ","
                                                  )
                                                ])
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName: "v-b-modal.customize",
                                                modifiers: { customize: true }
                                              }
                                            ],
                                            attrs: { variant: "link" },
                                            on: {
                                              click: function($event) {
                                                return _vm.customizeOrderUpdate(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Customize")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-right",
                                attrs: { cols: "2" }
                              },
                              [_vm._v(" " + _vm._s(item.price) + " ")]
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("b-form-spinbutton", {
                                  attrs: {
                                    id: "demo-sb",
                                    max: "100",
                                    min: "0",
                                    inline: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.spin(item, $event)
                                    }
                                  },
                                  model: {
                                    value: item.qty,
                                    callback: function($$v) {
                                      _vm.$set(item, "qty", $$v)
                                    },
                                    expression: "item.qty"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "custom",
          attrs: { id: "customize", centered: "", "hide-footer": true }
        },
        [
          _vm.activeItem
            ? _c(
                "b-container",
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.activeItem.name) +
                      " " +
                      _vm._s(_vm.activeItem.price) +
                      " "
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-5" },
                    _vm._l(_vm.activeItem.menu_modifiers, function(option) {
                      return _c(
                        "b-col",
                        {
                          key: option.id,
                          staticClass: "mt-2",
                          attrs: { md: "12" }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(option.name) +
                                " (select min " +
                                _vm._s(option.min) +
                                " and max) " +
                                _vm._s(option.max)
                            )
                          ]),
                          _vm._l(option.modifiers, function(modifier) {
                            return _c(
                              "div",
                              { key: modifier.id },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      value: modifier,
                                      disabled:
                                        _vm.activeItem.selected_groups.length >=
                                          option.max &&
                                        _vm.activeItem.selected_groups.indexOf(
                                          modifier
                                        ) === -1
                                    },
                                    model: {
                                      value: _vm.activeItem.selected_groups,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.activeItem,
                                          "selected_groups",
                                          $$v
                                        )
                                      },
                                      expression: "activeItem.selected_groups"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(modifier.name) + " ")]
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  _c("b-form-spinbutton", {
                    attrs: { id: "demo-sb", max: "100", min: "1", inline: "" },
                    model: {
                      value: _vm.activeItem.qty,
                      callback: function($$v) {
                        _vm.$set(_vm.activeItem, "qty", $$v)
                      },
                      expression: "activeItem.qty"
                    }
                  }),
                  !_vm.isUpdate
                    ? _c(
                        "b-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.addToCart(_vm.activeItem, "customize")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " ADD TO ORDER - " +
                              _vm._s(
                                _vm.activeItem.qty * _vm.activeItem.price
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.isUpdate
                    ? _c(
                        "b-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.updateCart(_vm.activeItem, "customize")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " UPDATE ORDER - " +
                              _vm._s(
                                _vm.activeItem.qty * _vm.activeItem.price
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }