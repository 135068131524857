<template>
  <div>
    <div class="header">
      <b-container>
        <b-row class="cart-header">
          <b-col cols="5">
            <div class="delivery-detail">
              <h2>Luganze</h2>
              <h4>Fast Food, Continental</h4>
              <h5 />
              <h3>45 Mins <span>Delivery </span></h3>
            </div>
          </b-col>

        </b-row>

        <!-- :arrows="true"
            :infinite="false"
            :slides-to-show="5"
            :slides-to-scroll="5" -->

        <div class="mb-2 border-top">
          <VueSlickCarousel
            v-if="categories.length"
            v-bind="sliderSettings"
          >
            <div
              v-for="category in categories"
              :key="category.id"
              class="m-2 item-categories"
              :class="[category.id == activeCategory ? 'active' : '']"
              @click="activeCategory = category.id"
            >
              {{ category.name }}
            </div>
          </VueSlickCarousel>
        </div>
        <b-row>
          <b-col
            v-for="item in grouped"
            :key="item.id"
            sm="12"
            md="6"
          >
            <b-card class="mb-2">
              <b-row no-gutters>
                <b-col md="6">
                  <b-card-body
                    :title="item.name"
                    class="item-card"
                  >
                    <b-card-text>
                      <div class="align-items-left item-list">
                        <h4>${{ item.price }}</h4>
                        <p>
                          6pcs fired/grilled, someting, there it it, fired and
                          fired stuffs all over!
                        </p>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-col>
                <b-col
                  md="6"
                  class="text-right"
                >
                  <div class="w-100">
                    <b-card-img
                      src="https://picsum.photos/400/400/?image=20"
                      alt="Image"
                      class="rounded-0 item-img"
                    />
                  </div>
                  <div class="w-100">
                    <b-form-spinbutton
                      id="demo-sb"
                      v-model="item.qty"
                      max="100"
                      min="0"
                      inline
                      @change="spin(item, $event)"
                    />
                    <b-button
                      v-if="item.qty == 0"
                      class="add-to-cart-btn"
                      @click="addToCart(item)"
                    >Add</b-button>
                  </div>
                  <b-button
                    v-if="item.menu_modifiers"
                    v-b-modal.customize
                    variant="link"
                    @click="customizeOrder(item)"
                  >
                    Customize
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-if="$store.state['cart'].cartItems.length > 0"
          class="bg-gradient-secondary footer"
        >
          <b-row>
            <b-col cols="3">
              <b-button v-b-modal.modal-1>
                Cart
              </b-button>
              Your Order ({{ totalQty }})
            </b-col>
            <b-col cols="6">
              <p> Total {{ totalAmount.toFixed(2) }}</p>
            </b-col>
            <b-col cols="3">
              <router-link to="cart">
                <b-button>PROCEED</b-button>
              </router-link>
            </b-col>
          </b-row>
        </div>
        <!-- <b-row class="mt-3">
            <div id="footer"><b-button v-b-modal.modal-1>Cart</b-button>
            <div class="view-options">ddd</div>
            <div class="cart">
              <b-row>
                <b-col>
                  <b-row>
                    <h3 class="cartitem-price">
                      {{ $store.state["cart"].cartItems.length }} Items |
                      {{ totalAmount.toFixed(2) }}
                    </h3>
                  </b-row>
                  <b-row>
                    <h3 class="cart-store">from Luganze</h3>
                  </b-row>
                </b-col>
                <b-col class="align-items-right">
                  <div class="d-flex justify-content-end mt-3 mr-2">
                    <router-link
                      :to="{name: 'cart'}"
                    >
                      <h3 class="view-cart mr-1">View Cart</h3>
                    </router-link>
                    <feather-icon
                      icon="ShoppingBagIcon"
                      size="14"
                      class="align-middle text-body shoping-icon"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
            </div>
        </b-row> -->

      </b-container>
    </div>
    <b-modal
      id="modal-1"
      title="Your Orders"
      modal-class="cart-details-modal"
      size="xl"
      :hide-footer="true"
    >
      <b-container>
        <div class="header">
          <b-row
            v-for="(item,index) in $store.state['cart'].cartItems"
            :key="'cart-'+item.id"
          >
            <b-card class="w-100 mb-1">
              <b-row>
                <b-col
                  cols="8"
                  class="text-center"
                >
                  {{ item.name }}
                  <span v-if="item.selected_groups.length > 0">
                    <p>
                      <span
                        v-for="modifier in item.selected_groups"
                        :key="modifier.id"
                      >
                        <small> {{ modifier.name }},</small>
                      </span>
                    </p>
                    <b-button
                      v-b-modal.customize
                      variant="link"
                      @click="customizeOrderUpdate(item,index)"
                    >Customize</b-button>
                  </span>
                </b-col>
                <b-col
                  cols="2"
                  class="text-right"
                >
                  {{ item.price }}
                </b-col>
                <b-col cols="2">
                  <b-form-spinbutton
                    id="demo-sb"
                    v-model="item.qty"
                    max="100"
                    min="0"
                    inline
                    @change="spin(item, $event)"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      id="customize"
      ref="custom"
      centered
      :hide-footer="true"
    >
      <b-container v-if="activeItem">
        {{ activeItem.name }} {{ activeItem.price }}
        <b-row class="mt-5">
          <b-col
            v-for="option in activeItem.menu_modifiers"
            :key="option.id"
            md="12"
            class="mt-2"
          >
            <!-- <b-form-checkbox v-model="activeItem.selected_groups" :value="option"> -->
            <strong>{{ option.name }} (select min {{ option.min }} and max)
              {{ option.max }}</strong>
            <!-- </b-form-checkbox> -->

            <div
              v-for="modifier in option.modifiers"
              :key="modifier.id"
            >
              <b-form-checkbox
                v-model="activeItem.selected_groups"
                :value="modifier"
                :disabled="activeItem.selected_groups.length >= option.max && activeItem.selected_groups.indexOf(modifier) === -1"
              >
                {{ modifier.name }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-form-spinbutton
          id="demo-sb"
          v-model="activeItem.qty"
          max="100"
          min="1"
          inline
        />
        <b-button
          v-if="!isUpdate"
          @click="addToCart(activeItem,'customize')"
        >
          ADD TO ORDER - {{ activeItem.qty * activeItem.price }}
        </b-button>
        <b-button
          v-if="isUpdate"
          @click="updateCart(activeItem,'customize')"
        >
          UPDATE ORDER - {{ activeItem.qty * activeItem.price }}
        </b-button>

      </b-container>
    </b-modal>

  </div>
</template>

<script>
import store from '@/store'
import { ref, computed } from '@vue/composition-api'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel,
  },

  setup(props, context) {
    const categories = ref([])

    const productList = ref([])

    const activeCategory = ref(null)

    const cart = ref([])

    const activeItem = ref(null)

    // const custom = ref(null)
    const isUpdate = ref(false)

    const activeCartItemIndex = ref(null)

    const sliderSettings = ref(
      {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrow: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
        ],
      },
    )

    const getIndex = item => item.findIndex(i => i.id === 2)

    store.dispatch('cart/fetchCategories').then(res => {
      categories.value = res.data
      activeCategory.value = categories.value[0].id
    })

    store.dispatch('cart/fetchItems').then(res => {
      productList.value = res.data
    })

    const addToCart = (item, isCustomize = null) => {
      if (item.qty === 0) {
        // eslint-disable-next-line no-param-reassign
        item.qty = 1
      }
      store.dispatch('cart/addToCart', item).then(() => {
        if (isCustomize != null) {
          const record = productList.value.find(el => el.id === activeItem.value.id)
          record.qty += activeItem.value.qty
          context.refs.custom.hide()
        }
      })
    }

    const updateCart = item => {
      store.state.cart.cartItems[activeCartItemIndex.value] = JSON.parse(JSON.stringify(item))
      localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
      activeItem.value = JSON.parse(JSON.stringify(item))
    }

    const grouped = computed(() => {
      const groups = []
      groups.value = productList.value.filter(
        item => item.item_group === activeCategory.value,
      )
      return groups.value
    })

    const modifierChange = event => {
      activeItem.value.selected_groups[event.id] = event
    }

    const totalAmount = computed(() => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.price * i.qty
      })
      return total
    })

    const totalQty = computed(() => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.qty
      })
      return total
    })

    const spin = (item, ev) => {
      const record = store.state.cart.cartItems.find(i => i.id === item.id)
      if (!record && ev !== 0) {
        addToCart(item)
      } else if (ev === 0) {
        const index = store.state.cart.cartItems.findIndex(
          el => el.id === item.id,
        )
        if (index !== -1) {
          store.state.cart.cartItems.splice(index, 1)
        }
      } else {
        record.qty = ev
      }
      localStorage.setItem('cart', JSON.stringify(store.state.cart.cartItems))
    }

    const customizeOrder = item => {
      activeItem.value = JSON.parse(JSON.stringify(item))
      activeItem.value.qty = 1
    }

    const customizeOrderUpdate = (item, index) => {
      activeItem.value = JSON.parse(JSON.stringify(item))
      isUpdate.value = true
      activeCartItemIndex.value = index
    }

    return {
      customizeOrder,
      customizeOrderUpdate,
      activeCategory,
      modifierChange,
      totalAmount,
      totalQty,
      categories,
      activeItem,
      addToCart,
      updateCart,
      isUpdate,
      grouped,
      getIndex,
      cart,
      spin,
      sliderSettings,
    }
  },
}
</script>

<style lang="scss">
.header {
  .delivery-detail {
    h2 {
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      color: #49e0db;
    }
    h4 {
      text-align: left;
      font-size: 18px;
      line-height: 21px;
      color: #b2b6c1;
    }
    h5 {
      text-align: left;
      font-size: 12px;
      line-height: 17px;
    }
    h3 {
      text-align: left;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      span {
        font-weight: 400;
        color: #b2b6c1;
      }
    }
  }
}
.cart-header {
  // border-bottom: 0.5px solid #bdc3c7;
  position: sticky;
  top: 0;
  z-index: 400;
  /*background-color: #fff;*/
}
.cart {
  width: 482px;
  height: 46px;
  background-color: #1f7dbb;
  float: right;
  border-radius: 5px;
  .cart-store {
    position: relative;
    font-size: 11px;
    color: #f2f2f2;
    left: 20px;
  }
  .view-cart {
    position: relative;
    font-size: 14px;
    color: #f2f2f2;
    font-weight: bold;
    svg {
      color: #f2f2f2;
    }
  }
  .cartitem-price {
    position: relative;
    float: left;
    font-size: 14px;
    left: 20px;
    top: 6px;
    color: #f2f2f2;
    font-weight: 700;
  }
  .cartitem-from {
    position: relative;
    float: left;
    font-size: 14px;
  }
}
.item-categories {
  display: flex;
  // border-right: 1px solid #000000;
  font-size: 18px;
  padding: 10px;
  color: #1f7dbb;
  cursor: pointer;
}

.item-categories:last-child {
  border-right: none;
}

.item-categories.active {
  font-weight: bold;
  &:after {
    position: absolute;
    content: "";
    // border-bottom: 1px solid #d2d7da;
    width: 100%;
    transform: translateX(-50%);
    bottom: 0px;
  }
}

// .item-categories {
//   list-style: none;
//   display: flex;
//   li {
//     border-right: 1px solid #000000;
//     font-size: 18px;
//     padding: 10px;
//     color: #1f7dbb;
//     max-width: 15%;
//   }
//   li:last-child {
//     border-right: none;
//   }
// }
.item-card {
  .card-title {
    color: #1f7dbb;
    font-size: 18px;
    font-weight: 500;
  }
  h4 {
    color: #60616c;
    font-size: 16px;
  }
  p {
    color: #b2b6c1;
    font-size: 12px;
  }
}
.item-img {
  width: 110px;
  height: 108px;
}
.add-to-cart-btn {
  position: absolute;
  right: 28px;
}
.shoping-icon {
  color: #f2f2f2 !important;
}
.slick-slide {
  width: auto !important;
}
.slick-prev:before, .slick-next:before{
  color: #287dbb
}
.footer {
  position: sticky;
  bottom: 0;
  z-index: 99999;
  background-color: #F2F2F2;
  padding: 12px 20px 0px;
}
.cart-details-modal .modal-dialog {
  position: absolute;
  bottom: -200px;
  left: -15px;
  right: 0;
  margin: auto;
  transition: 0.5s;
  max-width: 1110px;
}
// .modal .modal-dialog {

  // max-width: 1110px;
  // left: -15px;
// }
.modal.show .modal-dialog {
  bottom: 48px;
  transition: 0.5s;
  // max-width: 1110px;
  // left: -15px;
}
.modal.show .modal-dialog .modal-content{
  border-radius: 0px;
  border: 0px;
}
.modal-open .modal.cart-details-modal{
      overflow-y: hidden;
}
.bg-gradient-secondary h4{
  font-size: 24px;
  color: #1F7DBB;
  line-height: 29px;
  display: inline-block;
  margin-bottom: 0px;
}
.bg-gradient-secondary p{
  text-align: right;
  font-size: 24px;
   line-height: 29px;
   font-weight: 700;
   color: #60616C;
   margin-bottom: 0px;
}
.cart-details-modal h5{
  font-size: 24px;
  line-height: 29px;
  color: #60616C;
  font-weight: 400;
}
</style>
